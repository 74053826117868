export const COLORS = {
  lightWhite: "#FBFDFF",
  lightGray: "#F3F5F6",
  mediumGray: "#B0B8BA",
  gray: "#A0A4A6",
  accent: "#6C757D",
  primary: "#212529",
}

export const GRADIENT = `linear-gradient(to top right, ${COLORS.accent}, ${
  COLORS.primary
})`

export const BORDER_RADIUS = "10px"
