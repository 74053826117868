import React from "react"
import PropTypes from "prop-types"

import { COLORS } from "../styles/constants"
import { Link } from "gatsby"

const Footer = ({ siteTitle }) => (
  <footer
    style={{
      padding: "1rem",
      backgroundColor: COLORS.lightGray,
    }}
  >
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "1rem 2rem",
        fontSize: ".85rem",
      }}
    >
      <div style={{ color: COLORS.primary, fontWeight: 700 }}>
        <Link
          style={{ textDecoration: "none", marginRight: '1rem' }}
          to="/terms"
        >
          Terms
        </Link>
        <a
          style={{ textDecoration: "none", marginRight: '1rem' }}
          href="https://www.buymeacoffee.com/charlietango8"
          target={'_blank'}
        >
          Buy me a coffee ⤴︎
        </a>
        <a
          style={{ textDecoration: "none", marginRight: '1rem' }}
          href="mailto:catalin@charlietango.co"
        >
          Contact ️📪
        </a>
      </div>
      <div style={{ color: COLORS.gray }}>
        © {new Date().getFullYear()}
        {` `}
        {siteTitle}
      </div>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
